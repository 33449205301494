@if (label) {
  <div
    class="mb-2 text-sm font-medium"
    [ngClass]="{
      'text-gray-400': disabled,
      'text-gray-900': !disabled
    }"
  >
    {{ label }}
    @if (required) {
      <span class="text-sm font-medium text-red-600"> * </span>
    }
  </div>
}
<div class="relative" (clickOutside)="showDropdown = false">
  <button
    class="inset-shadow-1 font-base inline-flex w-full items-center justify-between gap-1 rounded-lg border border-gray-300 bg-gray-50 px-2 py-3 text-sm"
    type="button"
    (click)="showDropdown = disabled ? showDropdown : !showDropdown"
    [ngClass]="{
      '!border-red-500 bg-primary-50 text-red-700 placeholder-red-700 focus:!border-red-500':
        formControl.invalid && formControl.enabled && formControl.touched,
      'text-gray-400': !formControl.value || formControl.value.length === 0,
      'cursor-not-allowed !border-gray-400 !bg-gray-200 !text-gray-400':
        disabled
    }"
  >
    {{
      ((formControl.value | typeof) === "object"
        ? dropdownPlaceholder
        : formControl.value) ||
        dropdownPlaceholder ||
        "-"
    }}
    <svg-icon
      src="/assets/icons/chevron-down.svg"
      class="h-2.5 w-2.5 transition-all"
      [ngClass]="{
        'rotate-180 text-gray-900': showDropdown,
        'text-gray-400': !showDropdown
      }"
      [applyClass]="true"
    ></svg-icon>
  </button>
  <div
    class="absolute left-0 top-full z-30 max-h-0 w-full overflow-hidden rounded-lg border bg-white transition-all"
    [ngClass]="{
      'mt-2 max-h-[200px] overflow-y-auto': showDropdown,
      'opacity-0': !showDropdown
    }"
    #scrollContainer
  >
    <ul class="py-2 text-gray-700" (click)="showDropdown = isMultiSelect">
      <ng-content select="app-dropdown-item"></ng-content>
    </ul>
  </div>
</div>
